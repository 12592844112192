import Immutable from 'immutable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from '@alkem/react-ui-tooltip';

import { hasPermissionModule } from 'modules/permissions';
import {
  ORGANIZATION_PERMISSION,
  USERLABEL_MANAGE_PERMISSION,
} from 'modules/permissions/const';
import { selectUser } from 'modules/user';
import { selectHasManageLabelPermission } from 'reducers/productVersion';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { track } from 'utils/tracking';

import {
  detachUserLabels,
  fetchLinkedUserLabels,
  showUserLabelModal,
} from '../../actions';
import {
  selectProductUserLabels,
  selectUserLabelsProductId,
  selectUserLabelsProductVersionId,
} from '../../selectors/product-labels';
import { UserLabels } from '../../types';
import { hasUserLabelManagementFeature } from '../../utils';
import { UserLabel } from '../label';
import { UserLabelModal } from '../modal';

import './index.scss';

export const ProductUserLabels = () => {
  const dispatch = useDispatch();
  const user: UserImmutable = useSelector(selectUser);
  const canAttachOrDetachLabels: boolean = useSelector(
    selectHasManageLabelPermission,
  );
  const productVersionId: number = useSelector(
    selectUserLabelsProductVersionId,
  );
  const productId: number = useSelector(selectUserLabelsProductId);
  const userLabels: UserLabels = useSelector(selectProductUserLabels);
  const hasUserLabelManagement = hasUserLabelManagementFeature(user);

  useEffect(() => {
    dispatch(fetchLinkedUserLabels(productId));
  }, [productId, dispatch, user]);

  if (!hasUserLabelManagement) {
    return null;
  }

  const canCreateOrUpdateLabels = hasPermissionModule(
    user,
    ORGANIZATION_PERMISSION,
    USERLABEL_MANAGE_PERMISSION,
  );

  let labels: UserLabels = userLabels;
  let hiddenLabels: UserLabels = Immutable.List();

  if (userLabels.size > 6) {
    labels = userLabels.slice(0, 6) as UserLabels;
    hiddenLabels = userLabels.slice(6, userLabels.size) as UserLabels;
  }

  const onAdd = () => {
    track({
      category: 'product-page-tracking',
      action: 'add label',
    });
    dispatch(
      showUserLabelModal({
        ...(productId && { productKeyIds: [productId] }),
        productVersionIds: [productVersionId],
        selectedLabelIds: userLabels.map((lbl) => ({
          id: get(lbl, 'id'),
          partial: false,
        })),
      }),
    );
  };

  const onDelete = canAttachOrDetachLabels
    ? (_: string, id: number) => {
        dispatch(
          detachUserLabels({
            userLabelIds: Immutable.List([id]),
            productVersionIds: [productVersionId],
            ...(productId && { productKeyIds: [productId] }),
          }),
        );
      }
    : null;

  return (
    <div className="ProductUserLabels">
      {labels.map((label) => (
        <UserLabel
          key={`product-user-label-${get(label, 'id')}`}
          id={get(label, 'id')}
          name={get(label, 'name')}
          onDelete={onDelete}
        />
      ))}
      {hiddenLabels.size > 0 && (
        <div
          className="ProductUserLabels__moreLabels"
          data-tip={hiddenLabels.map((lbl) => get(lbl, 'name')).join('<br />')}
          data-for="product-page-header-more-labels"
          data-html
        >
          {i18n.t('+{{count}} more', { count: hiddenLabels.size })}
          <Tooltip place="bottom" id="product-page-header-more-labels" />
        </div>
      )}
      {canAttachOrDetachLabels || canCreateOrUpdateLabels ? (
        <button
          type="button"
          className="ProductUserLabels__add"
          onClick={onAdd}
        >
          <i className="mdi mdi-plus-circle" />
          <span>{i18n.t('Add a tag')}</span>
        </button>
      ) : null}
      <UserLabelModal />
    </div>
  );
};
