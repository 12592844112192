import { List, fromJS } from 'immutable';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import { Spinner } from '@alkem/react-ui-spinner';

import Modal from 'components/ui/modal';
import { HistoryDiffLine } from 'modules/history';
import { UserImmutable } from 'types';
import { DiffImmutable } from 'types/diff';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import { fetchPatchesDiff } from '../../api';
import { isDataOpsPatcher } from '../../selectors';
import { DataOpsPatchOrNewPatch } from '../../types';
import { mergeDiffs } from '../../utils';

import './diff-modal.scss';

interface Props {
  onClose: () => void;
  patch: DataOpsPatchOrNewPatch;
  user: UserImmutable;
  onCancelPatch: (patch: DataOpsPatchOrNewPatch) => void;
  canPatch?: boolean;
}

export function DataOpsDiffModal({
  patch,
  onClose,
  user,
  onCancelPatch,
  canPatch = true,
}: Props) {
  const isMounted = useRef(true);
  const [diffList, setDiffList] = useState<List<DiffImmutable>>(List());
  const [isLoading, setLoading] = useState(true);
  const [diffError, setDiffError] = useState(false);
  const isPatcher = isDataOpsPatcher(user);

  async function fetchDiff() {
    try {
      const response = await fetchPatchesDiff(patch.productKeyId, [patch]);
      if (!isMounted.current) {
        return;
      }
      const { diff_old = [], diff_new = [] } = response[patch.fieldName];
      setDiffList(
        fromJS(isPatcher ? mergeDiffs(diff_old, diff_new) : diff_new),
      );
    } catch (error) {
      logError(error);
      setDiffError(true);
    }
    setLoading(false);
  }

  useEffect(
    () => {
      fetchDiff();
      return () => {
        isMounted.current = false;
      };
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  let patchedBy = '';
  if ('user' in patch) {
    if (isPatcher) {
      patchedBy = patch.user.username || user.get('username');
    } else {
      patchedBy = patch.user.belongsTo?.[0]?.nameLegal || patch.user.username;
    }
  } else {
    patchedBy = user.get('username');
  }

  const columnSize = isPatcher ? 'col-xs-3' : 'col-xs-4';

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t(
        'frontproductstream.product_collaborative_edit.single_field_diff_modal_header.text',
        { defaultValue: 'Patched values' },
      )}
      className="DataOpsDiffModal"
      confirmButtonText={i18n.t(
        'frontproductstream.product_collaborative_edit.single_field_diff_modal_close.button',
        { defaultValue: 'Close' },
      )}
      onConfirm={onClose}
      onClose={onClose}
      secondaryAction={
        canPatch && isPatcher
          ? [
              i18n.t(
                'frontproductstream.product_collaborative_edit.single_field_diff_modal_cancel_patch.button',
                { defaultValue: 'Cancel patch' },
              ),
              () => {
                onCancelPatch(patch);
                onClose();
              },
              { secondary: false, warning: true },
            ]
          : undefined
      }
      hideCloseButton
    >
      <p className="DataOpsDiffModal__lastUpdate">
        {i18n.t(
          'frontproductstream.product_collaborative_edit.single_field_diff_modal_last_update_info.text',
          {
            defaultValue: 'Patched by {{user}} on {{date}}',
            user: patchedBy,
            date: moment(
              ('updatedAt' in patch ? patch.updatedAt : null) || undefined,
            ).format('LL'),
          },
        )}
      </p>
      <div className="ProductHistoryDiffCard__header row">
        <div className={columnSize}>
          {i18n.t(
            'frontproductstream.product_collaborative_edit.single_field_diff_modal_table_column_field_name.label',
            { defaultValue: 'Field name' },
          )}
        </div>
        <div className={columnSize}>
          {i18n.t(
            'frontproductstream.product_collaborative_edit.single_field_diff_modal_table_column_manufacturer_value.label',
            { defaultValue: 'Manufacturer value' },
          )}
        </div>
        {isPatcher && (
          <div className={columnSize}>
            {i18n.t(
              'frontproductstream.product_collaborative_edit.single_field_diff_modal_table_column_new_manufacturer_value.label',
              { defaultValue: 'New manufacturer value' },
            )}
          </div>
        )}
        <div className={columnSize}>
          {i18n.t(
            'frontproductstream.product_collaborative_edit.single_field_diff_modal_table_column_retailer_value.label',
            { defaultValue: 'Retailer value' },
          )}
        </div>
      </div>
      {isLoading ? (
        <Spinner className="DataOpsDiffModal__spinner" medium center />
      ) : (
        diffList.map(
          (diff) =>
            diff && (
              <HistoryDiffLine
                key={`${diff?.get('model')}-${diff?.get('name')}`}
                diff={diff}
                withCurrent={isPatcher}
                notHidden
              />
            ),
        )
      )}
      {diffError && !isLoading && (
        <p className="DataOpsDiffModal__error">
          {i18n.t(
            'frontproductstream.product_collaborative_edit.single_field_diff_modal_error_loading_data.text',
            { defaultValue: 'An error occured' },
          )}
        </p>
      )}
    </Modal>
  );
}
